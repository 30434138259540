<script>
    import { Router, link } from 'svelte-routing';

    const cases = [
        {
            href: '/hyundai',
            src: './img/ss/hyundai-logo.svg',
            size: '20.6rem',
            alt: 'Hyundai',
            description:
                'Hyundai USA drives incremental, high-quality shoppers, outperforming SEM.',
        },
        {
            href: '/honda',
            src: './img/ss/honda-logo.svg',
            size: '19rem',
            alt: 'Honda',
            description:
                'SoCal Honda Dealers drives video views and dealer searches outperforming YouTube and programmatic display.',
        },
    ];
</script>

<section id="success" class="Section Success">
    <h6 class="Section-eyebrow Section-bleed">Our Success -</h6>
    <div class="Section-bleed Success-stories">
        <Router>
            {#each cases as { href, src, size, alt, description }}
                <a {href} use:link class="Card" target="_blank">
                    <div class="Card-content">
                        <img
                            {src}
                            {alt}
                            class="Card-img"
                            style="max-width: {size}"
                        />
                        <div class="Card-txt">
                            <p>{description}</p>
                            <div
                                class="flex a-end-h a-center-v mtxs uppercase w-bold pt12 txt-primary"
                            >
                                View case study
                                <svg class="icon mlxs">
                                    <use
                                        xlink:href="./img/sprite.svg#icon-chevron-right"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
            {/each}
        </Router>
    </div>
</section>
