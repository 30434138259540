<script>
    import Header from '../Layout/Header.svelte';
    import Hero from '../Components/Hero.svelte';
    import Banner from '../Components/Banner.svelte';
    import About from '../Components/About.svelte';
    import Features from '../Components/Features.svelte';
    import Device from '../Components/Device.svelte';
    import Success from '../Components/Success.svelte';
    import Featured from '../Components/Featured.svelte';
    import Contact from '../Components/Contact.svelte';
</script>

<span class="Home">
    <Header />
    <Header sticky={true} />
    <main class="Main">
        <Hero />
        <Banner />
        <About />
        <Features />
        <Device />
        <Success />
        <Featured />
        <Contact />
    </main>
</span>
