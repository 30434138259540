<script>
    import HeaderSecond from '../Layout/HeaderSecond.svelte';
    import Hero from '../Honda/Hero.svelte';
    import Testimonial from '../Honda/Testimonial.svelte';
    import Overview from '../Honda/Overview.svelte';
    import Overview2 from '../Honda/Overview2.svelte';
    import Who from '../Components/Who.svelte';
    import Contact from '../Components/Contact.svelte';
</script>

<span class="Case Case--honda">
    <HeaderSecond />
    <HeaderSecond sticky={true} />
    <main class="Main">
        <Hero />
        <Testimonial />
        <Overview />
        <Overview2 />
        <Who />
        <Contact />
    </main>
</span>
