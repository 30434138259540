<script>
    import Logo from '../Components/Logo.svelte';

    const year = new Date().getFullYear();
    const partners = [
        { img: 'tag', alt: 'tag' },
        { img: 'iab', alt: 'iab' },
        { img: 'ias', alt: 'IAS' },
        { img: 'moat', alt: 'MOAT' },
    ];
    const nav = [
        { url: 'terms-and-conditions/31134588', title: 'Terms of Service' },
        { url: 'privacy-policy/31134588', title: 'Privacy Policy' },
    ];
</script>

<footer class="Footer">
    <div class="Footer-connect">
        <div>
            <h5 class="mbxs">Our Industry Moves Fast</h5>
            <p class="txt-color-secondary h7">
                Sign up to remain up to date on the latest news, trends and
                upcoming events.
            </p>

            <form id="signup-form" class="Footer-connectField flex a-start-v">
                <div class="Field mbni mrs">
                    <input
                        type="email"
                        id="signup_email"
                        name="signup_email"
                        placeholder="Company Email*"
                        class="Input"
                        required
                    />
                </div>

                <button
                    id="signup-submit"
                    type="submit"
                    class="Btn Btn--square"
                >
                    <svg class="icon">
                        <use xlink:href="./img/sprite.svg#icon-arrow" />
                    </svg>
                </button>
            </form>
        </div>
        <div>
            <h5 class="mbs">Connect With Us</h5>
            <a
                href="https://www.linkedin.com/company/motormaven/"
                class="Btn Btn--square"
                target="_blank"
                rel="noopener"
            >
                <svg class="icon">
                    <use xlink:href="./img/sprite.svg#icon-linkedin" />
                </svg>
            </a>
        </div>
    </div>
    <div class="Footer-main">
        <Logo />
        <div class="flex a-center">
            <h5 class="Section-eyebrow">OUR PARTNERS –</h5>
            <ul class="flex">
                {#each partners as { img, alt }}
                    <li><img src="./img/partners/{img}.png" {alt} /></li>
                {/each}
            </ul>
        </div>
    </div>
    <div class="Footer-credits">
        <p class="txt-color-secondary h7 mani">
            Copyright © {year} motormaven. All Rights Reserved.
        </p>

        <nav class="Nav Nav--horizontal">
            <ul class="Nav-list">
                {#each nav as { url, title }}
                    <li class="Nav-item">
                        <a
                            href="https://www.iubenda.com/{url}"
                            class="Nav-link iubenda-nostyle no-brand iubenda-embed"
                            {title}>{title}</a
                        >
                        <script type="text/javascript">
                            (function (w, d) {
                                var loader = function () {
                                    var s = d.createElement('script'),
                                        tag = d.getElementsByTagName(
                                            'script'
                                        )[0];
                                    s.src =
                                        'https://cdn.iubenda.com/iubenda.js';
                                    tag.parentNode.insertBefore(s, tag);
                                };
                                if (w.addEventListener) {
                                    w.addEventListener('load', loader, false);
                                } else if (w.attachEvent) {
                                    w.attachEvent('onload', loader);
                                } else {
                                    w.onload = loader;
                                }
                            })(window, document);
                        </script>
                    </li>
                {/each}
            </ul>
        </nav>
    </div>
</footer>
