<script>
    import { Router, link } from 'svelte-routing';
    import Logo from '../Components/Logo.svelte';
    export let sticky = false;
</script>

<header
    id={sticky ? 'header-sticky' : 'header'}
    class="Header {sticky ? 'Header--sticky' : ''}"
>
    <div class="Header-content">
        <Router>
            <a href="/#header" use:link class="Header-logo">
                <Logo />
            </a>
        </Router>
        <span />

        <a href="#contact" class="Btn phone-noni">Get In Touch</a>
    </div>
</header>
