<script>
    const features = [
        {
            title: 'Innovative Approach',
            description:
                'MotorMaven elevates digital advertising to a turbocharged, results-driven, innovative approach. MotorMaven is laser-focused on generating onsite high-value actions, and our exclusive placements mean our partners see incremental results at scale.',
            items: [
                { icon: 'car', label: 'Built For Automotive' },
                { icon: 'performance', label: 'Proven Performance' },
            ],
        },
        {
            title: 'Intelligent Data',
            description:
                'Reach in-market users at scale using Maven Audiences™, a proprietary data solution built for automotive. Maven Audiences collects a broad, rich foundation of data including proprietary, real-time auto intender data, which fuels optimizations and drives results.',
            items: [
                { icon: 'user', label: 'Automotive Audiences' },
                { icon: 'timer', label: 'Real-Time Intent' },
            ],
        },
        {
            title: 'Proven Technology',
            description:
                'Backed by a powerful AI-enhanced matching engine, real-time performance data, and algorithms built to optimize audience and post-click conversion data at scale.',
            items: [
                { icon: 'lock', label: 'Fraud Protection' },
                { icon: 'shield', label: 'Brand Safety' },
            ],
        },
        {
            title: 'Expert People',
            description:
                'We bring a dedicated team of experts—strategists, analysts, creatives, and technical specialists—making it easy to get you started and see results fast.',
            items: [
                { icon: 'branches', label: 'Fully Managed' },
                { icon: 'gauge', label: 'Quick results' },
            ],
        },
    ];
</script>

<section id="features" class="Section Features">
    <div class="Section-bleed">
        <h6 class="Section-eyebrow">Our features -</h6>
        {#each features as { title, description, items }, i}
            <div class="Features-item">
                <img src="./img/features/features-{i + 1}.png" alt={title} />
                <div class="Features-itemMain">
                    <h3 class="mbs">{title}</h3>
                    <div class="pll">
                        <p>{description}</p>
                        <ul class="flex a-center-v">
                            {#each items as { icon, label }}
                                <li class="flex a-center-v">
                                    <svg>
                                        <use
                                            xlink:href="./img/sprite.svg#icon-{icon}"
                                        />
                                    </svg>
                                    <h6>{label}</h6>
                                </li>
                            {/each}
                        </ul>
                    </div>
                </div>
            </div>
        {/each}
    </div>
</section>
