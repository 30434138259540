<script>
    const overview = [
        {
            icon: 'flag',
            title: 'Challenge',
            description:
                'Source new audiences and drive incremental high-value actions (HVAs) for Hyundai models at scale, without cannibalizing existing advertising campaigns, at a cost per acquisition (CPA) that performs in line or better than current channels.',
        },
        {
            icon: 'target',
            title: 'Technology',
            description:
                'A fully-connected plan powered by Maven’s proprietary intent engine was activated to prospect and drive new in-market shoppers to HyundaiUSA.com.',
        },
        {
            icon: 'check',
            title: 'Result',
            description:
                'With Maven, Hyundai USA succeeded in sourcing new audiences and drove incremental HVAs at a CPA that was 27% lower than their existing non-brand SEM efforts. These HVAs converted at a remarkable 11% conversion rate and provided a 13% lift in conversion volume over SEM for three model lines: Hyundai Santa Fe, Hyundai Tucson, and Hyundai Kona.',
        },
    ];
</script>

<section class="Section Overview2">
    <div class="Section-bleed Overview-secondary">
        <div>
            {#each overview as { icon, title, description }}
                <div class="Overview-secondaryItem">
                    <svg class="no-shrink mvxs mrl i45s">
                        <use xlink:href="./img/sprite.svg#icon-{icon}" />
                    </svg>
                    <div>
                        <h3 class="mbs">{title}</h3>
                        <p class="mll-phone">{description}</p>
                    </div>
                </div>
            {/each}
        </div>
        <div>
            <h6 class="Section-eyebrow">Results by the numbers -</h6>
            <ul>
                <li class="mbl">
                    <h2 class="txt-gradient">27%</h2>
                    <p>Lower CPA than SEM</p>
                </li>
                <li class="mbl">
                    <h2 class="txt-gradient">11%</h2>
                    <p>
                        High-value action <br />
                        conversion rate
                    </p>
                </li>
                <li>
                    <h2 class="txt-gradient">13%</h2>
                    <p>
                        Incremental lift over <br />
                        SEM
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section>
